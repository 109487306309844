import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useState } from 'react'

export default function PayrollSectionCard({
  title = '',
  children,
  expand = false,
  button
}) {

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <span>{title}</span>
        <div className={Styles.headerButton}>
          {!!button && button}
          {expand && (
            <div className={Styles.expandButton} onClick={() => {setIsExpanded(!isExpanded)}}>
              <Icon icon={isExpanded ? "arrow-up" : "arrow-down" } size='24px' />
            </div>
          )}
        </div>
      </div>

      {(!expand || isExpanded) && (
        <>
          <div className={Styles.underline} />
          <div>
            {children}
          </div>
        </>
      )}
    </div>
  )
}