import Icon from '@Atom/Icon'
import { capitalizedFirstLetter } from '@Helpers/capitalizedFirstLetter'
import Images from '@Theme/Images'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Styles from './style.module.scss'
import Input from '@Atom/Input'
import moment from 'moment'
import AttachmentCard from '@Atom/AttachmentCard'
import TextAreaFileInput from '@Molecule/TextAreaFileInput'
import AssignTicketModal from '@Molecule/_modal/AssignTicketModal'
import Skeleton, { CircleSkeleton } from '@Atom/Skeleton'

export default function FeedbackDetailsLayout({
  packageDetails,
  isLoading
}) {
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState('information')
  const [handler, setHandler] = useState('')

  const [message, setMessage] = useState('')
  const [files, setFiles] = useState([])
  const [showTransfer, setShowTransfer] = useState(false)

  const navigate = useNavigate()

  const role = useMemo(() => {
    return 'HANDLER' // Replace with actual role logic
    // return 'ADMIN' // Replace with actual role logic
  }, [])

  const data = useMemo(() => {
    return {
      id: 4,
      ticketID: id,
      category: packageDetails?.issueType,
      user: {
        name: packageDetails?.employeeName,
        profilePicture: Images.AVA_DEFAULT,
        clientName: packageDetails?.clientName,
        phoneNumber: packageDetails?.employeePhoneNumber || '-',
        email: packageDetails?.employeeEmail || '-',
        idNumber: packageDetails?.employeeID,
        ksNumber: '-',
        jhtNumber: '-',
      },
      timestamp: new Date(),
      handler: {
        name: '-',
        profilePicture: Images.AVA_DEFAULT,
        clientName: '-',
      },
      firstResponseTime: moment(packageDetails?.firstResponseTime)?.format('LLL'),
      averageResponseTime: '-',
      sla: '-',
      status: [packageDetails?.status, packageDetails?.stage]
    }
  }, [id, packageDetails?.clientName, packageDetails?.employeeEmail, packageDetails?.employeeID, packageDetails?.employeeName, packageDetails?.employeePhoneNumber, packageDetails?.firstResponseTime, packageDetails?.issueType, packageDetails?.stage, packageDetails?.status])

  useEffect(() => {
    setHandler(data?.handler?.name)
  }, [data?.handler?.name])

  const histories = useMemo(() => {
    return [
      {
        title: 'Pengaduan Diterima',
        timestamp: new Date(),
        description: 'Pengaduan terkait [Kategori Pengaduan] telah diterima'
      },
      {
        title: 'Pengaduan Terlambat direspon',
        timestamp: new Date(),
        description: 'Sudah lebih dari 30 menit pengaduan diberikan tindakan apapun.',
        isViolated: true
      },
      {
        title: 'Pengaduan Telah di Assign',
        timestamp: new Date(),
        description: '[Nama Penanggung jawab] telah ditugaskan untuk menangani pengaduan terkait [kategori pengaduan]'
      },
      {
        title: 'Pengaduan Belum Terselesaikan',
        timestamp: new Date(),
        description: 'Pengaduan belum selesai terhitung 2x24 jam sejak waktu tiket di assign kepada penanggung jawab.',
        isViolated: true
      },
      {
        title: 'Ticket Ditransfer',
        timestamp: new Date(),
        description: 'Ticket pengaduan telah diteruskan kepada [nama manager] '
      }
    ]
  }, [])

  const ratings = useMemo(() => {
    return [
      {
        manager: 'Junaedi Ahmad Budi Sugiono',
        handler: 'Sugiono Budi Ahmad Junaedi',
        firstResponseTime: '34 Menit',
        averageResponseTime: '1 Menit 30 Detik',
        rating: null,
        feedback: null
      },
      {
        manager: 'Junaedi Ahmad Budi Sugiono',
        handler: 'Sugiono Budi Ahmad Junaedi',
        firstResponseTime: '34 Menit',
        isViolated: true,
        averageResponseTime: '1 Menit 30 Detik',
        rating: 3,
        feedback: 'Corem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
    ]
  }, [])

  const chats = useMemo(() => {
    return [
      {
        type: 'user',
        timestamp: new Date(),
        subject: 'BPJS Saya TIdak Dapat Digunakan Di Rumah Sakit',
        message: 'Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent.',
        attachments: [
          {
            id: 1,
            fileName: 'example.jpg',
            fileUrl: 'https://i.pinimg.com/736x/18/d0/b2/18d0b2f08e4a20ec0c6d67c57ff8e37c.jpg',
            fileType: 'image/jpeg',
          },
          {
            id: 1,
            fileName: 'example.pdf',
            fileUrl: 'https://drive.google.com/file/d/1DnDld5IwJ_yPcPUAe9FdY-Ydg-5spCof/view?usp=drive_link',
            fileType: 'application/pdf',
          },
        ],
      },
      {
        type: 'handler',
        timestamp: new Date(),
        message: 'Halo [Nama pelapor] terima kasih telah menggunakan layanan pengaduan kami. Untuk sekarang ini pengaduan kamu sedang dalam proses pengecekan, harap tunggu untuk mendapatkan informasi lanjutan terkait pengaduan kamu ya.'
      },
      {
        type: 'user',
        timestamp: new Date(),
        message: 'Horem ipsum dolor sit amet, consectetur adipiscing elit. '
      }
    ]
  }, [])

  const messageTemplates = useMemo(() => {
    return [
      'Haloo..',
      'Hii..',
      'Ok'
    ]
  }, [])

  const leftContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className={Styles.information}>
          <Section className={Styles.statusSection}>
            <Skeleton>
              <div className={Styles.indicators}>
                <Icon color={data?.status?.find(obj => obj === 'RECEIVED') ? '#22CFBA' : '#DEE2E7'} icon={'document-previous'} size={20} />
                <Icon color={data?.status?.find(obj => obj === 'VIOLATION') ? '#D42701' : '#DEE2E7'} icon={'flag'} size={20} />
                <Icon color={data?.status?.find(obj => obj === 'TRANSFER') ? '#2E3192' : '#DEE2E7'} icon={'document-forward'} size={20} />
                <div className={Styles.messageIcon}>
                  <Icon color={data?.status?.find(obj => obj?.includes('MESSAGE')) ? '#22970F' : '#DEE2E7'} icon={'message'} size={20} />
                  {
                    data?.status?.find(obj => obj?.includes('MESSAGE'))
                    &&
                    <span>
                      {data?.status?.find(obj => obj?.includes('MESSAGE'))?.split('-')[1]}
                    </span>
                  }
                </div>
              </div>
            </Skeleton>

            <Skeleton height='36px'>
              <div className={`${Styles.status} ${Styles[data?.status[0]?.toLowerCase()]}`}>
                {`${capitalizedFirstLetter(data?.status[0])} : ${data?.status[1] === 'NEW'
                  ? 'Baru'
                  : (
                    data?.status[1] === 'ON-PROSESS'
                      ?
                      'Dalam Proses'
                      :
                      'Selesai'
                  )}`}
              </div>
            </Skeleton>

          </Section>
          <Section className={Styles.userSection}>
            <span>
              <Skeleton width='96px' height='20px' >
                Detail Pelapor
              </Skeleton>
            </span>
            <div className={Styles.profile}>
              <CircleSkeleton height='52px'>
                <img src={data?.user?.profilePicture || Images.AVA_DEFAULT} alt='' />
              </CircleSkeleton>
              <div className={Styles.userName}>
                <Skeleton height='26px'>
                  <span>{data?.user?.name}</span>
                </Skeleton>
                <p style={{ paddingTop: '4px' }}>
                  <Skeleton height='20px'>
                    {data?.user?.clientName}
                  </Skeleton>
                </p>
              </div>
            </div>
            <div className={Styles.userDetails}>
              {
                [
                  {
                    label: 'No Hp',
                    value: data?.user?.phoneNumber
                  },
                  {
                    label: 'Email',
                    value: data?.user?.email
                  },
                  {
                    label: 'No KTP',
                    value: data?.user?.idNumber
                  },
                  {
                    label: 'No BPJS KS',
                    value: data?.user?.ksNumber
                  },
                  {
                    label: 'No BPJS JHT',
                    value: data?.user?.jhtNumber
                  }
                ]
                  ?.map((detail, i) => (
                    <Skeleton key={i}>
                      <div className={Styles.detail}>
                        <span>{detail?.label}</span>
                        <span>:</span>
                        <span>{detail?.value}</span>
                      </div>
                    </Skeleton>

                  ))
              }
            </div>
          </Section>
          {
            role === 'ADMIN' &&
            <Section className={Styles.transferSection}>
              <Skeleton height='36px'>
                <button onClick={() => setShowTransfer(true)}>
                  <span>Transfer Ticket</span>
                  <Icon icon={'document-forward'} size={16} />
                </button>
              </Skeleton>
            </Section>
          }
          {
            role === 'ADMIN' &&
            <Section className={Styles.handlerSection}>
              <Skeleton width='124px' />
              <Skeleton height='44px'>
                <Input
                  title='Penanggung Jawab'
                  disabled={data?.handler}
                  value={handler}
                  setValue={setHandler}
                  placeholder='Pilih penanggung jawab'
                  dropdownOptions={[
                    {
                      name: 'Yon Safira',
                      picture: Images.AVA_DEFAULT
                    }
                  ]}
                  isUserAutocomplete
                />
              </Skeleton>

              <Skeleton height='40px'>
                <button disabled={data?.handler}>Simpan</button>
              </Skeleton>
            </Section>
          }

          <Section className={Styles.categorySection}>
            <div>
              <span style={{ paddingBottom: '4px' }}>
                <Skeleton width='150px' >
                  Kategori Pengaduan
                </Skeleton>
              </span>

              <div className={Styles.category}>
                <div className={Styles.bpjsLogo}>
                  <CircleSkeleton height='32px'>
                    <img src={Images.BPJS} alt='' />
                  </CircleSkeleton>
                </div>
                <Skeleton height='24px' width='80px'>
                  <span>{data?.category}</span>
                </Skeleton>
              </div>
            </div>
            <div>
              <span style={{ paddingBottom: '4px' }}>
                <Skeleton width='180px' >
                  Tanggal dan Waktu Pengaduan Diterima
                </Skeleton>
              </span>
              <Skeleton>
                <p>{moment(data?.timestamp)?.format('LLL')}</p>
              </Skeleton>
            </div>
            <div>
              <span style={{ paddingBottom: '4px' }}>
                <Skeleton width='180px' >
                  Tanggal dan Waktu Penyelesaian Pengaduan
                </Skeleton>
              </span>
              <Skeleton>
                <p>{data?.sla}</p>
              </Skeleton>
            </div>
          </Section>
        </div>
      )
    }
    switch (activeTab) {
      case 'history':
        return (
          <div className={Styles.histories}>
            {
              histories?.map((history, i) => (
                <div key={i} className={`${Styles.history} ${Styles[history?.isViolated ? 'violated' : '']}`}>
                  <div className={`${Styles.bullet}`} />
                  <div className={Styles.historyDetails}>
                    <span>{history?.title}</span>
                    <span>{moment(history?.timestamp)?.format('LLL')}</span>
                    <span>{history?.description}</span>
                  </div>
                </div>
              ))
            }
          </div>
        )
      case 'rating':
        return (
          <div className={Styles.ratings}>
            {
              ratings?.map((rating, i) => (
                <Section key={i} className={Styles.rating}>
                  <span>Performa Penerima {i + 1}</span>
                  <div className={Styles.ratingDetails}>
                    {
                      [
                        {
                          title: 'Manager Penerima',
                          value: rating?.manager
                        },
                        {
                          title: 'Penanggung Jawab',
                          value: rating?.handler,
                        },
                        {
                          title: 'First Response Time',
                          value: rating?.firstResponseTime,
                          className: Styles[rating?.isViolated ? 'red' : '']
                        },
                        {
                          title: 'Average Response Time',
                          value: rating?.averageResponseTime,
                        },
                        {
                          title: 'Rating',
                          value: rating?.rating ? (
                            <div className={Styles.ratingValue}>
                              <img src={Images.EMO_1} className={Styles[rating?.rating < 1 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_2} className={Styles[rating?.rating < 2 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_3} className={Styles[rating?.rating < 3 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_4} className={Styles[rating?.rating < 4 ? 'transparent' : '']} alt='' />
                              <img src={Images.EMO_5} className={Styles[rating?.rating < 5 ? 'transparent' : '']} alt='' />
                              <span>{rating?.rating}/5</span>
                            </div>
                          ) : '-'
                        }
                      ]
                        ?.map((obj, i) => (
                          <div key={i}>
                            <span>{obj?.title}</span>
                            <span>:</span>
                            <div>
                              {obj?.value || '-'}
                            </div>
                          </div>
                        ))
                    }
                  </div>
                </Section>
              ))
            }
          </div>
        )
      default:
        return (
          <div className={Styles.information}>
            <Section className={Styles.statusSection}>
              <div className={Styles.indicators}>
                <Icon color={data?.status?.find(obj => obj === 'RECEIVED') ? '#22CFBA' : '#DEE2E7'} icon={'document-previous'} size={20} />
                <Icon color={data?.status?.find(obj => obj === 'VIOLATION') ? '#D42701' : '#DEE2E7'} icon={'flag'} size={20} />
                <Icon color={data?.status?.find(obj => obj === 'TRANSFER') ? '#2E3192' : '#DEE2E7'} icon={'document-forward'} size={20} />
                <div className={Styles.messageIcon}>
                  <Icon color={data?.status?.find(obj => obj?.includes('MESSAGE')) ? '#22970F' : '#DEE2E7'} icon={'message'} size={20} />
                  {
                    data?.status?.find(obj => obj?.includes('MESSAGE'))
                    &&
                    <span>
                      {data?.status?.find(obj => obj?.includes('MESSAGE'))?.split('-')[1]}
                    </span>
                  }
                </div>
              </div>
              <div className={`${Styles.status} ${Styles[data?.status[0]?.toLowerCase()]}`}>
                {`${capitalizedFirstLetter(data?.status[0])} : ${data?.status[1] === 'NEW'
                  ? 'Baru'
                  : (
                    data?.status[1] === 'ON-PROSESS'
                      ?
                      'Dalam Proses'
                      :
                      'Selesai'
                  )}`}
              </div>
            </Section>
            <Section className={Styles.userSection}>
              <span>Detail Pelapor</span>
              <div className={Styles.profile}>
                <img src={data?.user?.profilePicture || Images.AVA_DEFAULT} alt='' />
                <div className={Styles.userName}>
                  <span>{data?.user?.name}</span>
                  <p>{data?.user?.clientName}</p>
                </div>
              </div>
              <div className={Styles.userDetails}>
                {
                  [
                    {
                      label: 'No Hp',
                      value: data?.user?.phoneNumber
                    },
                    {
                      label: 'Email',
                      value: data?.user?.email
                    },
                    {
                      label: 'No KTP',
                      value: data?.user?.idNumber
                    },
                    {
                      label: 'No BPJS KS',
                      value: data?.user?.ksNumber
                    },
                    {
                      label: 'No BPJS JHT',
                      value: data?.user?.jhtNumber
                    }
                  ]
                    ?.map((detail, i) => (
                      <div key={i} className={Styles.detail}>
                        <span>{detail?.label}</span>
                        <span>:</span>
                        <span>{detail?.value}</span>
                      </div>
                    ))
                }
              </div>
            </Section>
            {
              role === 'ADMIN' &&
              <Section className={Styles.transferSection}>
                <button onClick={() => setShowTransfer(true)}>
                  <span>Transfer Ticket</span>
                  <Icon icon={'document-forward'} size={16} />
                </button>
              </Section>
            }
            {
              role === 'ADMIN' &&
              <Section className={Styles.handlerSection}>
                <Input
                  title='Penanggung Jawab'
                  disabled={data?.handler}
                  value={handler}
                  setValue={setHandler}
                  placeholder='Pilih penanggung jawab'
                  dropdownOptions={[
                    {
                      name: 'Yon Safira',
                      picture: Images.AVA_DEFAULT
                    }
                  ]}
                  isUserAutocomplete
                />
                <button disabled={data?.handler}>Simpan</button>
              </Section>
            }

            <Section className={Styles.categorySection}>
              <div>
                <span>Kategori Pengaduan</span>
                <div className={Styles.category}>
                  <div className={Styles.bpjsLogo}>
                    <img src={Images.BPJS} alt='' />
                  </div>
                  <span>{data?.category}</span>
                </div>
              </div>
              <div>
                <span>Tanggal dan Waktu Pengaduan Diterima</span>
                <p>{moment(data?.timestamp)?.format('LLL')}</p>
              </div>
              <div>
                <span>Tanggal dan Waktu Penyelesaian Pengaduan</span>
                <p>{data?.sla}</p>
              </div>
            </Section>
          </div>
        )
    }
  }, [activeTab, data?.category, data?.handler, data?.sla, data?.status, data?.timestamp, data?.user?.clientName, data?.user?.email, data?.user?.idNumber, data?.user?.jhtNumber, data?.user?.ksNumber, data?.user?.name, data?.user?.phoneNumber, data?.user?.profilePicture, handler, histories, isLoading, ratings, role])

  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <div className={Styles.leftHeader}>
          <h3>Detail Pengaduan {id}</h3>
          <div className={Styles.tabs}>
            {
              [
                {
                  label: 'Informasi Umum',
                  value: 'information'
                },
                {
                  label: 'Riwayat',
                  value: 'history'
                },
                {
                  label: 'Rating',
                  value: 'rating'
                }
              ]
                ?.map((tab, i) => (
                  <div key={i} onClick={() => setActiveTab(tab?.value)} className={`${Styles.tab} ${Styles[activeTab === tab?.value ? 'active' : '']}`}>
                    {tab?.label}
                  </div>
                ))
            }
          </div>
        </div>
        <div className={Styles.leftContent}>
          {leftContent}
        </div>
      </div>
      <div className={Styles.right}>
        <div className={Styles.rightHeader}>
          <button className={Styles.backButton} onClick={() => navigate(-1)}>
            <Icon icon={'arrow-chevron-left'} size={20} />
            <span>Kembali</span>
          </button>
          {
            role === 'HANDLER' && !isLoading
            &&
            <div className={Styles.handlerExtraHeader}>
              <button>
                <Icon icon={'tick-circle'} size={16} />
                <span>Request Tutup Tiket</span>
              </button>
              <span>Total Request : 0</span>
            </div>
          }
        </div>
        {
          isLoading
            ?
            <div className={Styles.rightContent}>
              <Skeleton
                height='100%'

              />
            </div>
            :
            <div className={Styles.rightContent}>

              <div className={Styles.chats}>
                {
                  chats?.map((chat, i) => (
                    <div key={i} className={Styles.chat}>
                      {
                        chat?.type === 'user'
                          ?
                          <img src={data?.user?.profilePicture || Images.AVA_DEFAULT} alt='' />

                          :
                          <div className={Styles.mwsLogo}>
                            <img src={Images.MWS_LOGO} alt='' />
                          </div>
                      }
                      <div className={Styles.chatDetails}>
                        {chat?.subject && <span>{chat?.subject}</span>}
                        <p>{chat?.message}</p>
                        {
                          chat?.attachments?.length
                          &&
                          <div className={Styles.attachments}>
                            {chat?.attachments?.map((attachment, j) => (
                              <AttachmentCard
                                key={j}
                                fileName={attachment?.fileName}
                                fileType={attachment?.fileType}
                                fileUrl={attachment?.fileUrl}
                              />
                            ))}
                          </div>

                        }
                      </div>
                      <span>{moment(chat?.timestamp)?.format('HH:mm')}</span>
                      {
                        i !== chats.length - 1 && (
                          <div className={Styles.divider} />
                        )
                      }

                    </div>
                  ))
                }
              </div>
              <div className={Styles.messageBox}>
                {
                  role === 'ADMIN'
                    ?
                    <p>Kamu tidak memiliki akses untuk melakukan percakapan dengan pelapor</p>
                    :
                    <div className={Styles.handlerBox}>
                      <TextAreaFileInput
                        text={message}
                        files={files}
                        setFiles={setFiles}
                        setText={setMessage}
                        withSendButton
                        templates={messageTemplates}
                      />
                    </div>
                }
              </div>

            </div>
        }

      </div>
      <AssignTicketModal
        show={showTransfer}
        data={data}
        onClose={() => setShowTransfer(false)}
        onSuccess={() => {
        }}
        isTransfer
      />
    </div>
  )
}

const Section = ({ children, className = '' }) => {

  return (
    <div className={`${Styles.section} ${className}`}>
      {children}
    </div>
  )
}