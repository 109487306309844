import React, { Fragment, useEffect, useMemo, useState } from "react";
import Styles from "./Style.module.scss";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TopBar from "@Atom/TopBar";
import useWindowSize from "@Hooks/useWindowSize";
import Tooltip from "@Atom/Tooltip";
import { makeRandomString } from "helpers/makeRandomString";
import { Link } from "react-router-dom";
import SelectClientModal from "@Molecule/_modal/SelectClientModal";

export default function SideBar({ children, logo }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showClients, setShowClients] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const sideItems = useMemo(() => {
    return [
      {
        icon: "element-3",
        title: "Dashboard",
        path: "",
        hidden: true,
      },
      {
        icon: "people",
        path: "people",
        title: "Karyawan",
        subMenu: [
          {
            title: "Daftar Karyawan",
            path: "/people",
            icon: "personalcard",
          },
          {
            title: "Struktur Organisasi",
            path: "/people/organization",
            icon: "org-chart",
          },
        ],
      },
      {
        icon: "calendar",
        title: "Absensi",
        path: "attendance",
      },
      {
        icon: "task-square",
        title: "Permintaan",
        path: "requests",

        subMenu: [
          {
            title: "Menu",
            path: "requests",
            icon: "personalcard",
          },
          {
            title: "Menu",
            path: "requests",
            icon: "personalcard",
          },
        ],
        hidden: true,
      },
      {
        icon: "receipt-2",
        title: "Payroll",
        path: "payroll",
      },
      {
        icon: "dropbox",
        title: "Fasilitas",
        path: "facilities",
        hidden: true,
      },
      {
        icon: "message-question",
        title: "Pengaduan",
        path: "feedback",
        // hidden: true
      },
      {
        icon: "activity",
        title: "Pusat Kegiatan",
        path: "activities-center",
        hidden: true,
      },
      {
        icon: "add-item",
        title: "Pengelolaan Konten",
        path: "content-management",
        subMenu: [
          {
            title: "Menu",
            path: "content-management",
            icon: "personalcard",
          },
          {
            title: "Menu",
            path: "content-management",
            icon: "personalcard",
          },
        ],
        hidden: true,
      },
      {
        id: makeRandomString(5),
        icon: "setting-2",
        title: "Pengaturan",
        path: "settings",
        subMenu: [
          {
            title: "Profil Perusahaan",
            path: "settings",
            icon: "building",
          },
          {
            title: "Organisasi",
            path: "settings",
            icon: "folder-open",
            grandChild: [
              {
                title: "menu",
                path: "settings/payroll/",
              },
              {
                title: "menu",
                path: "settings/payroll/epay-slip",
              },
            ],
          },
          {
            title: "Validasi",
            path: "settings",
            icon: "task-square",
            grandChild: [
              {
                title: "menu",
                path: "settings/payroll/",
              },
              {
                title: "menu",
                path: "settings/payroll/epay-slip",
              },
            ],
          },
          {
            title: "Payroll",
            path: "settings/payroll",
            icon: "receipt-2",
            grandChild: [
              {
                title: "Komponen Penggajian",
                path: "settings/payroll",
              },
              {
                title: "Template Slip Gaji",
                path: "settings/payroll/epay-slip",
              },
            ],
          },
        ],
        hidden: true,
      },
    ];
  }, []);

  const filteredSideItems = sideItems?.filter((obj) => !obj?.hidden);

  const sideActive = useMemo(() => {
    return filteredSideItems.findIndex(
      (val) => val.path === pathname.split("/")[1]
    );
  }, [pathname, filteredSideItems]);

  const handleCloseExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!isExpanded) {
      sessionStorage.removeItem("sideBarActive");
    }
  }, [isExpanded]);

  useEffect(() => {
    if (sideActive) {
      setIsExpanded(true);
    }
  }, [sideActive]);
  const [isOpacity, setIsOpacity] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpacity(true);
    }, 400);
    setIsOpacity(false);
  }, [isExpanded]);

  const [isActiveSubMenu, setIsActiveSubMenu] = useState(
    +sessionStorage.getItem("activeSubMenu") || 0
  );

  const handleActiveSubMenu = (e) => {
    setIsActiveSubMenu(e);
  };

  useEffect(() => {
    sessionStorage.setItem("activeSubMenu", isActiveSubMenu);
  }, [isActiveSubMenu]);

  // useEffect(() => {
  //   if (isActiveSubMenu === sideActive) {
  //     setIsActiveSubMenu(isActiveSubMenu);
  //   } else {
  //     setIsActiveSubMenu(sideActive);
  //   }
  // }, [isActiveSubMenu, pathname, sideActive]);

  const { width } = useWindowSize();

  // MOBILE
  const [isActiveSubMenuMobile, setIsActiveSubMenuMobile] = useState(0);

  const handleActiveSubMenuMobile = (e) => {
    setIsActiveSubMenuMobile(e);
  };
  const [isOpenSideBarMobile, setIsOpenSideBarMobile] = useState(false);
  const handleOpenSideMobile = () => {
    setIsOpenSideBarMobile(!isOpenSideBarMobile);
  };
  const [isOpenSubMenu, setIsOpenSubMenu] = useState();
  const [isCloseSubMenu, setIsCloseSubMenu] = useState();
  const handleCloseSubMenu = (idx) => {
    setIsOpenSubMenu(idx);
    if (isOpenSubMenu === idx) {
      setIsOpenSubMenu(!idx);
      setIsCloseSubMenu(!isCloseSubMenu);
    }
  };
  const { id } = useParams();

  let breadcrumbs = [];
  switch (pathname) {
    case "/people":
      breadcrumbs = [
        { label: "Karyawan", path: "/people" },
        { label: "Daftar Karyawan", path: "/people" },
      ];
      break;
    case "/people/organization":
      breadcrumbs = [
        { label: "Karyawan", path: "/people" },
        { label: "Struktur Organisasi", path: "/people/organization" },
      ];
      break;
    case "/people/detail/" + id:
      breadcrumbs = [
        { label: "Karyawan", path: "/people" },
        { label: "Daftar Karyawan", path: "/people" },
        { label: "Detail Karyawan", path: "/people/detail" },
      ];
      break;
    case "/settings":
      breadcrumbs = [{ label: "Pengaturan", path: "/settings" }];
      break;
    case "/settings/payroll":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
      ];
      break;
    case "/settings/payroll/benefits-food":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        { label: "Tunjangan Makan", path: "/settings/payroll/benefits-food" },
      ];
      break;
    case "/settings/payroll/benefits-e-pays":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        {
          label: "Tunjangan Gaji Pokok",
          path: "/settings/payroll/benefits-e-pays",
        },
      ];
      break;
    case "/settings/payroll/benefits-e-pays/add-schema-salary":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        { label: "Gaji Pokok", path: "/settings/payroll/benefits-e-pays" },
        {
          label: "Tambah Skema Gaji Pokok",
          path: "/settings/payroll/benefits-e-pays/add-schema-salary",
        },
      ];
      break;
    case "/settings/payroll/feast-day":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        { label: "THR", path: "/settings/payroll/feast-day" },
      ];
      break;
    case "/settings/payroll/feast-day/add-schema-feast-day":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        { label: "THR", path: "/settings/payroll/feast-day" },
        {
          label: "Tambah Skema THR",
          path: "/settings/payroll/feast-day/add-schema-feast-day",
        },
      ];
      break;
    case "/settings/payroll/uak":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        { label: "Tunjangan UAK", path: "/settings/payroll/uak" },
      ];
      break;
    case "/settings/payroll/uak/add-schema-uak":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        { label: "UAK", path: "/settings/payroll/uak" },
        { label: "Tambah Skema UAK", path: "/settings/payroll/add-schema-uak" },
      ];
      break;
    case "/settings/payroll/tax":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll" },
        { label: "Pajak", path: "/settings/payroll/add-schema-tax" },
      ];
      break;
    case "/settings/payroll/tax/add-schema-tax":
      breadcrumbs = [
        { label: "Pengaturan", path: "/settings" },
        { label: "Payroll", path: "/settings/payroll" },
        { label: "Komponen Penggajian", path: "/settings/payroll/tax" },
        { label: "Pajak", path: "/settings/payroll/tax" },
        {
          label: "Tambah Skema Pajak",
          path: "/settings/payroll/tax/add-schema-tax",
        },
      ];
      break;
    default:
      breadcrumbs = [{ label: "", path: "" }];
  }

  const [isHoverIcon, setIsHoverIcon] = useState(false);

  const pageWithoutSideBar = !pathname.includes("people/detail"); // add page without sidebar

  const [activeGrandChild, setActiveGrandChild] = useState(
    +sessionStorage.getItem("activeGrandChild") || 0
  );
  const [isOpenGrandChild, setIsOpenGrandChild] = useState(
    +sessionStorage.getItem("isOpenGrandChild")
  );
  useEffect(() => {
    sessionStorage.setItem("isOpenGrandChild", isOpenGrandChild);
  }, [isOpenGrandChild]);

  useEffect(() => {
    sessionStorage.setItem("activeGrandChild", activeGrandChild);
  }, [activeGrandChild]);

  const handleOpendGrandChild = (idx) => {
    if (idx === isOpenGrandChild) {
      setIsOpenGrandChild(!idx);
    } else {
      const isHaveGrandChild =
        filteredSideItems[sideActive]?.subMenu?.length >= 1 &&
        filteredSideItems[sideActive]?.subMenu[isActiveSubMenu]?.grandChild
          ?.length >= 1;
      if (isHaveGrandChild) {
        setIsOpenGrandChild(idx);
      }
    }
  };

  useEffect(() => {
    setActiveGrandChild(0);
    if (
      filteredSideItems[sideActive]?.subMenu?.length >= 1 &&
      filteredSideItems[sideActive]?.subMenu[isActiveSubMenu]?.grandChild
        ?.length >= 1
    ) {
      navigate(filteredSideItems[sideActive]?.subMenu[isActiveSubMenu]?.path);
    }
  }, [isActiveSubMenu, navigate, sideActive, filteredSideItems]);

  useEffect(() => {
    sessionStorage.setItem("sideBarActive", isExpanded);
  }, [isExpanded]);

  return (
    <div className={Styles.containerSideBarMobile}>
      <TopBar setShowClients={setShowClients} onClick={handleOpenSideMobile} />
      {width >= 768 && (
        <section className={Styles.wrapperSideBar}>
          <div className={Styles.mainContain}>
            <div className={Styles.wrapperMenus}>
              <div className={Styles.logo}>
                <div>
                  <img
                    src={Images.MWS_LOGO}
                    alt="logo"
                    style={{
                      objectFit: "contain",
                      width: "40px",
                      cursor: "pointer",
                    }}
                    onClick={()=>navigate('/')}
                  />
                </div>
              </div>
              {filteredSideItems
                ?.filter((obj) => obj?.path !== "settings")
                ?.map((item, idx) => (
                  <div
                    key={`list-menu-${idx}`}
                    className={Styles.iconMenu}
                    is-active={
                      item?.path === "" || pathname === "/"
                        ? item?.path === "" && pathname === "/"
                          ? "true"
                          : "false"
                        : sideActive === idx
                          ? "true"
                          : "false"
                    }
                  >
                    <Tooltip
                      onMouseEnter={
                        sideActive === idx
                          ? () => { }
                          : () => setIsHoverIcon(idx)
                      }
                      onMouseLeave={
                        sideActive === idx
                          ? () => { }
                          : () => setIsHoverIcon(false)
                      }
                      text={filteredSideItems[isHoverIcon]?.title}
                      isActive={sideActive === idx}
                    >
                      <Icon
                        icon={item.icon}
                        size={"24"}
                        color={
                             ((sideActive === idx && item?.path !== "")
                             ||
                              (pathname === "/" && item?.path === "")
                              ? "#2E3192"
                              : "#A9B3C1")
                        }
                        style={{ transition: "0.3s" }}
                        className={Styles.navIcon}
                        onClick={() => {
                          navigate(item?.path);
                          setIsActiveSubMenu(0);
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
            </div>
            {filteredSideItems
              ?.filter((obj) => obj?.path === "settings")
              ?.map((item, idx) => (
                <div key={`last-list-menu-${item?.id}`}>
                  <div
                    className={Styles.iconMenu}
                    is-active={
                      item?.path === "" || pathname === "/"
                        ? item?.path === "" && pathname === "/"
                          ? "true"
                          : "false"
                        : pathname?.includes(item?.path)
                          ? "true"
                          : "false"
                    }
                  >
                    <Tooltip
                      onMouseEnter={
                        sideActive === item?.id
                          ? () => { }
                          : () => setIsHoverIcon(item?.id)
                      }
                      onMouseLeave={
                        sideActive === item?.id
                          ? () => { }
                          : () => setIsHoverIcon(false)
                      }
                      text={item.title}
                      isActive={sideActive === item?.id}
                    >
                      <Icon
                        icon={item.icon}
                        size={"24"}
                        color={
                            (pathname?.includes(item?.path) &&
                              item?.path !== "") ||
                              (pathname === "/" && item?.path === "")
                              ? "#2E3192"
                              : "#A9B3C1"
                        }
                        style={{ transition: "0.3s" }}
                        className={Styles.navIcon}
                        onClick={() => {
                          navigate(item?.path);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              ))}
          </div>
        </section>
      )}
      {width <= 768 && ( // mobileView
        <div className={Styles.overFlowSideMobile}>
          <section
            className={Styles.sideBarMobile}
            is-open={isOpenSideBarMobile?.toString()}
          >
            {isOpenSideBarMobile && (
              <div
                className={Styles.wrapperMenuMobile}
                is-opacity={isOpacity?.toString()}
              >
                <div className={Styles.headerMenu}>
                  <h2>Menu</h2>
                  <Icon
                    icon={"close-circle"}
                    className={Styles.iconCloseSideMobile}
                    onClick={handleOpenSideMobile}
                  />
                </div>
                <div className={Styles.listMenuMobile}>
                  {filteredSideItems?.map((item, idx) => (
                    <Fragment key={idx}>
                      <div
                        className={Styles.between}
                        is-active={sideActive === idx ? "true" : "false"}
                        onClick={() => {
                          // handleIconClick(idx);
                          handleCloseSubMenu(idx);
                          navigate(item?.path);
                        }}
                      >
                        <div>
                          <Icon
                            icon={item.icon}
                            className={Styles.iconMenuSideMobile}
                          />
                          <span>{item.title}</span>
                        </div>
                        {item.subMenu && (
                          <Icon
                            icon={"arrow-down-side"}
                            className={Styles.iconMenuSideMobileArrow}
                            onClick={() => handleCloseSubMenu(idx)}
                            is-close={isOpenSubMenu === idx ? "true" : "false"}
                            is-active-sub={(
                              sideActive === idx && isOpenSubMenu === false
                            ).toString()}
                          />
                        )}
                      </div>
                      {item.subMenu && isOpenSubMenu === idx && (
                        <div className={Styles.wrapperSubMenu}>
                          <div className={Styles.separatorLeft}></div>
                          <div className={Styles.subMenu}>
                            {item?.subMenu?.map((sub, idx) => (
                              <div
                                onClick={() => {
                                  handleActiveSubMenuMobile(idx);
                                  navigate(sub.path);
                                }}
                                key={idx}
                                is-active-sub-menu={(
                                  isActiveSubMenuMobile === idx
                                )?.toString()}
                              >
                                <span>{sub.title}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
          </section>
        </div>
      )}
      {width >= 768 &&
        filteredSideItems[sideActive]?.subMenu &&
        pageWithoutSideBar ? (
        <section>
          <div
            className={Styles.expanded}
            is-expanded={isExpanded?.toString()}
            style={
              width < 768 ||
                pathname.includes("add-employee") ||
                pathname.includes("organization")
                ? { position: "absolute" }
                : { undefined }
            }
          >
            <div
              className={Styles.closeExpanded}
              onClick={handleCloseExpand}
              is-expanded={isExpanded?.toString()}
            >
              <Icon
                icon={"arrow-down"}
                className={Styles.iconCloseExpanded}
                is-expanded={isExpanded?.toString()}
              />
            </div>

            {isExpanded && (
              <section
                className={Styles.listMenus}
                is-opacity={isOpacity?.toString()}
              >
                <h2>Karyawan</h2>
                <div className={Styles.wrapperMenus}>
                  {filteredSideItems.map(
                    (item, idx) =>
                      sideActive === idx &&
                      item?.subMenu?.map((sub, i) => (
                        <div className={Styles.viewerSubMenus} key={i}>
                          <div
                            onClick={() => {
                              handleActiveSubMenu(i);
                              isActiveSubMenu !== i && navigate(sub?.path);
                            }}
                            is-active={isActiveSubMenu === i ? "true" : "false"}
                          >
                            <Icon
                              icon={sub.icon}
                              size={"20"}
                              className={Styles.icon}
                            />
                            <span onClick={() => navigate(sub?.path)}>
                              {sub.title}
                            </span>
                            {sub?.grandChild && (
                              <Icon
                                icon={"arrow-down-side"}
                                color={
                                  isActiveSubMenu === i ? "white" : "#556377"
                                }
                                size={"20"}
                                className={Styles.grandChild}
                                onClick={() => handleOpendGrandChild(i)}
                                style={{
                                  transition: "0.2s",
                                  transform:
                                    isOpenGrandChild === i &&
                                      isActiveSubMenu === i
                                      ? "rotate(180deg)"
                                      : "",
                                }}
                              />
                            )}
                          </div>
                          {sub?.grandChild &&
                            isOpenGrandChild === i &&
                            isActiveSubMenu === i && (
                              <div className={Styles.wrapperGrandChild}>
                                <div
                                  className={Styles.divider}
                                  style={{
                                    height: `${sub?.grandChild?.length * 35}px`,
                                  }}
                                ></div>
                                <div className={Styles.wrapperMenu}>
                                  {sub.grandChild.map((g, ig) => (
                                    <div
                                      className={Styles.menus}
                                      is-active={(
                                        activeGrandChild === ig
                                      )?.toString()}
                                      key={ig}
                                      onClick={() => {
                                        setActiveGrandChild(ig);
                                        navigate(g?.path);
                                      }}
                                    >
                                      <span>{g?.title}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                        </div>
                      ))
                  )}
                </div>
              </section>
            )}
          </div>
        </section>
      ) : null}
      <div className={Styles.contentWrapper}>
        {width > 1024 && (
          <div className={Styles.wrapperBreadcrumbs}>
            <div
              is-absolute-sidebar={(
                isExpanded && pathname.includes("organization")
              )?.toString()}
            >
              {breadcrumbs.map((item, idx) => (
                <Fragment key={idx}>
                  <Link
                    to={item.path}
                    onClick={() =>
                      pathname === "/people/organization"
                        ? setIsActiveSubMenu(0)
                        : {}
                    }
                    className={Styles.text}
                    is-active={(breadcrumbs.length - 1 <= idx)?.toString()}
                  >
                    {item.label}
                  </Link>
                  {idx !== breadcrumbs.length - 1 && (
                    <Icon icon={"arrow-down-2"} color={"#3E4856"} size={14} />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {children}
      </div>
      {showClients && (
        <SelectClientModal
          show={showClients}
          onClose={() => setShowClients(false)}
        />
      )}
    </div>
  );
}
