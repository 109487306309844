import FileViewerModal from "@Atom/FileViewerModal";
import Icon from "@Atom/Icon";
import TagStatus from "@Atom/TagStatus";
import { get35DaysInMonth } from "helpers/get35DaysInMonth";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DetailAssetEmployee from "./AssetEmployee";
import DetailAttendanceEmployee from "./AttendanceEmployee";
import DetailCareerEmployee from "./CareerEmployee";
import DetailFinanceEmployee from "./FinanceEmployee";
import { DetailPersonalEmployee } from "./PersonalEmployee";
import Styles from "./style.module.scss";
import { fileBaseUrl } from "configs";
import AvatarIamges from "@Assets/Images/ava-default.png";

export default function DetailEmployee({ dataPersonaEmployee }) {
  const dataToTap = dataPersonaEmployee?.data?.data;
  const sideMenu = useMemo(() => {
    return [
      {
        title: "Informasi Karyawan",
        icon: "personalcard",
      },
      {
        title: "Kehadiran",
        icon: "calendar",
      },
      {
        title: "Karir",
        icon: "ranking",
      },
      {
        title: "Keuangan",
        icon: "money-4",
      },
      {
        title: "Aset",
        icon: "note",
      },
    ];
  }, []);

  const navigate = useNavigate();

  const startDate = useMemo(() => {
    return new Date();
  }, []);

  const attendanceData = useMemo(() => {
    const defaultData = [
      {
        date: "2024-10-01",
        value: "attend",
        timeStart: new Date("2024-10-01:08:00:00"),
        timeEnd: new Date("2024-10-01:15:00:00"),
      },
      {
        date: "2024-10-02",
        value: "sick",
      },
      {
        date: "2024-10-03",
        value: "leave",
      },
      {
        date: "2024-10-04",
        value: "permit",
      },
      {
        date: "2024-10-05",
        value: "absence",
      },
      {
        date: "2024-10-06",
        value: "sick",
      },
      {
        date: "2024-10-07",
        value: "leave",
      },
      {
        date: "2024-10-08",
        value: "permit",
      },
      {
        date: "2024-10-09",
        value: "absence",
      },
      {
        date: "2024-10-10",
        value: "sick",
      },
      {
        date: "2024-10-11",
        value: "leave",
      },
      {
        date: "2024-10-12",
        value: "permit",
      },
      {
        date: "2024-10-13",
        value: "absence",
      },
    ];
    // return defaultData

    const allDays = get35DaysInMonth(new Date(startDate));

    // const dateInMonths = getDaysInMonth(new Date(startDate)?.getMonth(), new Date(startDate)?.getFullYear(), 'dates')
    return allDays?.map((obj) => {
      const currentAttend = defaultData?.find(
        (d) => d?.date === moment(obj)?.format("YYYY-MM-DD")
      );
      if (currentAttend) {
        return {
          ...currentAttend,
          date: moment(obj)?.format("YYYY-MM-DD"),
        };
      }

      return {
        date: moment(obj)?.format("YYYY-MM-DD"),
        value:
          defaultData?.find(
            (d) => d?.date === moment(obj)?.format("YYYY-MM-DD")
          )?.value || "",
      };
    });
    // return []
  }, [startDate]);

  const activeSideMenu = +sessionStorage.getItem("activeMenuDetailEmployee");
  const [selectedSideMenu, setSelectedSideMenu] = useState(activeSideMenu || 1);

  useEffect(() => {
    sessionStorage.setItem("activeMenuDetailEmployee", selectedSideMenu);
  }, [selectedSideMenu]);

  const [viewFile, setViewFile] = useState(null);
  const [showFile, setShowFile] = useState(false);

  const sideMenuContent = useMemo(() => {
    switch (selectedSideMenu) {
      case 1:
        return (
          <DetailPersonalEmployee dataPersonaEmployee={dataPersonaEmployee} />
        );
      case 2:
        return (
          <DetailAttendanceEmployee
            attendances={attendanceData}
            dataPersonal={dataToTap}
          />
        );
      case 3:
        return (
          <DetailCareerEmployee
            dataPersonal={dataToTap}
            viewFile={viewFile}
            setViewFile={setViewFile}
            showFile={showFile}
            setShowFile={setShowFile}
          />
        );
      case 4:
        return (
          <DetailFinanceEmployee
            dataPersonal={dataToTap}
            viewFile={viewFile}
            setViewFile={setViewFile}
            showFile={showFile}
            setShowFile={setShowFile}
          />
        );
      case 5:
        return (
          <DetailAssetEmployee
            dataPersonal={dataToTap}
            viewFile={viewFile}
            setViewFile={setViewFile}
            showFile={showFile}
            setShowFile={setShowFile}
          />
        );
      default:
        return <DetailPersonalEmployee />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    attendanceData,
    dataPersonaEmployee,
    selectedSideMenu,
    showFile,
    viewFile,
  ]);

  return (
    <div className={Styles.containerDetailEmployee}>
      <div className={Styles.wrapperDetailEmployee}>
        <div className={Styles.nameDetail}>
          <Icon
            icon={"arrow-down"}
            color={"black"}
            size={"20"}
            className={Styles.arrowBack}
            onClick={() => navigate("/people")}
          />
          <span>{dataToTap?.name}</span>
        </div>
        <div className={Styles.mainContent}>
          <section className={Styles.sideMenu}>
            <div className={Styles.profileName}>
              <img
                src={
                  dataToTap?.imageURL === "" || dataToTap?.imageURL === "-"
                    ? AvatarIamges
                    : fileBaseUrl + dataToTap?.imageURL
                }
                alt="profile-picture"
                className={Styles.imageProfile}
              />
              <div className={Styles.nameAndPosition}>
                <span className={Styles.name}>{dataToTap?.name}</span>
                <span className={Styles.position}>
                  {/* Manager • Administrative Assistant */}
                  {/* {dataToTap?.contracts[0]?.grade || "-"}• */}
                  {dataToTap?.contracts[0]?.position || "-"}
                </span>
              </div>
              <TagStatus
                text={dataToTap?.status === "AKTIF" ? "Aktif" : "Tidak Aktif"}
                color={dataToTap?.status === "AKTIF" ? "green" : "red"}
              />
            </div>
            <div className={Styles.leader}>
              <span>Atasan langsung </span>
              <div className={Styles.nameAndImage}>
                <img
                  src={
                    dataToTap?.spvPic === "" ||
                    dataToTap?.spvPic === "-" ||
                    !dataToTap?.spvPic
                      ? AvatarIamges
                      : fileBaseUrl + dataToTap?.spvPic
                  }
                  alt="lead-images"
                  height={"32px"}
                  width={"32px"}
                />
                <span> {dataToTap?.spvName || "Nama Atasan"} </span>
              </div>
            </div>
            <div className={Styles.lineSeparator}></div>
            <div className={Styles.menus}>
              {sideMenu?.map((item, idx) => (
                <Fragment key={idx}>
                  <div
                    className={Styles.listMenu}
                    is-active={selectedSideMenu === idx + 1 ? "true" : "false"}
                    onClick={() => {
                      setSelectedSideMenu(idx + 1);
                    }}
                  >
                    <Icon icon={item.icon} className={Styles.icon} />
                    <span>{item.title}</span>
                  </div>
                  {idx !== sideMenu?.length - 1 && (
                    <div className={Styles.lineDashed}></div>
                  )}
                </Fragment>
              ))}
            </div>
          </section>

          <div className={Styles.divider}></div>

          {sideMenuContent}
        </div>
        {showFile && (
          <FileViewerModal
            isOpen={showFile}
            onClose={() => {
              setShowFile(false);
              setViewFile({});
            }}
            fileType={viewFile?.type}
            fileUrl={viewFile?.url}
            fileName={viewFile?.url?.split("/")?.pop()}
            hideOnMobile
          />
        )}
      </div>
    </div>
  );
}
