import { API } from "configs";

export function getFeedbackList(
  page,
  limit = '',
  search,
  isAdmin = 'false',
  receivedDate = '',
  assignedTo = '',
  status = '',
  stage = ''
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/ticket`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: { isAdmin, page, limit, search, receivedDate, assignedTo, status, stage }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}