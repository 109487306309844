import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss'
import Icon from '@Atom/Icon';
import FileUploadModal from '@Molecule/_modal/FileUploadModal';

export default function TextAreaFileInput({
  minTextFieldHeight = '44px',
  maxTextFieldHeight = '142px',
  text,
  setText,
  files,
  setFiles,
  placeholder = 'Type here...',
  withSendButton = false,
  onSend = () => { },
  templates = []
}) {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showUpload, setShowUpload] = useState(false)

  const handleChange = (event) => {
    setText(event.target.value);
    adjustTextareaHeight();
  };

  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '44px'; // Reset to initial height
      const newHeight = Math.min(textarea.scrollHeight, 142); // Limit to max height
      textarea.style.height = `${newHeight}px`;
    }
  };

  // Run on component mount and whenever text changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [text]);

  const fileIcon = (fileType) => {
    if (fileType.includes('pdf')) return 'pdf-2';
    if (fileType.includes('jpeg') || fileType.includes('jpg') || fileType.includes('png')) return 'image-2';
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        {
          !text && !!templates?.length
          &&
          <div className={Styles.templates}>
            {
              templates?.map((template, i) => (
                <div key={i} className={Styles.template}>
                  {template}
                </div>
              ))
            }
          </div>
        }
        {
          !!files?.length
          &&
          <div className={Styles.files} style={{paddingTop: !text && templates?.length ? '10px' : '0px'}}>
            {
              files?.map((file, i) => (
                <div key={i} className={Styles.file}>
                  <Icon icon={fileIcon(file?.type)} size={12} color={'#2E3192'}/>
                  <span>{file?.name}</span>
                  <button onClick={()=> setFiles(files?.filter(obj =>obj !== file))}>
                    <Icon icon={'close-circle'} size={8} color={'#D42701'}/>
                  </button>
                </div>
              ))
            }
          </div>
        }
      </div>
      <div className={Styles.bottom}>
        <div className={Styles.textAreaWrapper}>
          <button className={Styles.fileButton} onClick={() => setShowUpload(true)}>
            <Icon icon={'attachment'} size={20} />
          </button>
          <textarea
            ref={textareaRef}
            value={text}
            onChange={handleChange}
            style={{
              height: minTextFieldHeight,
              maxHeight: maxTextFieldHeight,
              overflow: 'auto', // Prevent scrollbars
              resize: 'none', // Disable manual resizing
            }}
            placeholder={placeholder}
          />
        </div>
        {
          withSendButton
          &&
          <button disabled={isSubmitting || !text} onClick={async () => {
            setIsSubmitting(true)
            onSend()
            setIsSubmitting(false)
          }}>
            <Icon icon={'send'} size={21.818} />
          </button>
        }
      </div>

      <FileUploadModal
        show={showUpload}
        onClose={()=> setShowUpload(false)}
        value={files}
        setValue={setFiles}
        isMultiple
      />

    </div>
  )
}