import Checkbox from "@Atom/Checkbox";
import Pagination from "@Atom/Pagination";
import Skeleton from "@Atom/Skeleton";
import TabHeader from "@Atom/TabHeader";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import useWindowSize from "@Hooks/useWindowSize";
import { Fragment, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function Table({
  columns,
  data = [],
  totalData = 0,
  totalPage = 0,
  withPagination = false,
  page = 1,
  setPage = () => { },
  limit = 10,
  setLimit = () => { },
  isLoading = false,
  className = "",
  withNumbering = false,
  expandComponent = (row) => <span>data</span>,
  withCheckbox,
  isChecked,
  setIsChecked,
  isDisabled,
  isDetailChart = false,
  onClickRow,
  widthNumber = "28px",
  isNumberSticky = false,
  height = "400px",
  heightEmpty = "50px",
  ...props
}) {
  const { width } = useWindowSize();

  const columnsToMap = useMemo(() => {
    if (width > 851) {
      return columns?.filter((obj) => !obj?.showOnMobile);
    }
    return columns?.filter((obj) => !obj?.hideOnMobile);
  }, [columns, width]);

  const [dataToMap, setDataToMap] = useState(data);

  useEffect(() => {
    setDataToMap(data);
  }, [data]);

  const handleCheckBox = (item) => {
    if (isChecked?.find((f) => f?.id === item?.id)) {
      const currentCheked = isChecked.filter((f) => f.id !== item?.id);
      setIsChecked(currentCheked);
    } else {
      setIsChecked([...isChecked, item]);
    }
  };

  const handleAllCheckBox = (data) => {
    if (data?.length !== isChecked?.length) {
      setIsChecked(data);
    } else {
      setIsChecked([]);
    }
  };

  return (
    <div
      className={`${Styles.container} ${className}`}
      style={{ minHeight: isDetailChart ? "100px" : { undefined } }}
      {...props}
    >
      <div
        className={Styles.tableWrapper}
        style={{
          height:
            !isLoading && !data?.length && !isDetailChart ? height : "unset",
        }}
      >
        <table className={Styles.table}>
          <thead>
            <tr>
              {withCheckbox && (
                <th style={{ width: "48px" }}>
                  <Checkbox
                    isChecked={isChecked?.length === dataToMap?.length}
                    setIsChecked={() => handleAllCheckBox(dataToMap)}
                    isDisabled={isDisabled}
                  />
                </th>
              )}
              {withNumbering && (
                <th
                  text="No"
                  className={Styles[isNumberSticky ? "sticky" : ""]}
                  style={{ width: width > 768 ? widthNumber : "20px" }}
                >
                  <TabHeader
                    text="No"
                    style={{ width: width > 768 ? widthNumber : "20px" }}
                  />
                </th>
              )}

              {columnsToMap?.map((c, cI) => (
                <th
                  key={cI}
                  style={{
                    textAlign: c?.align || "left",
                    minWidth: c?.width || "auto",
                    // zIndex: "-1",
                    // position: "relative",
                  }}
                  className={c?.tdClassName || ""}
                >
                  {c?.title}
                </th>
              ))}
              {/* {width <= 851 && <th style={{ width: "30px" }} />} */}
            </tr>
          </thead>
          <tbody>
            {!isLoading
              ? dataToMap?.map((d, dI) => (
                <Fragment key={dI}>
                  <tr
                    key={dI}
                    className={d?.className || ""}
                    onClick={() => (onClickRow ? onClickRow(d) : () => { })}
                    style={{ cursor: onClickRow ? "pointer" : "auto" }}
                  >
                    {withCheckbox && (
                      <td>
                        {
                          <Checkbox
                            isChecked={
                              isChecked?.find((f) => f.id === d?.id)
                                ? true
                                : false
                            }
                            setIsChecked={() => handleCheckBox(d)}
                            isDisabled={isDisabled}
                          />
                        }
                      </td>
                    )}
                    {withNumbering && (
                      <td className={Styles[isNumberSticky ? "sticky" : ""]}>
                        {dI + 1 + (page - 1) * limit}
                      </td>
                    )}

                    {columnsToMap?.map((c, cI) => (
                      <td
                        key={cI}
                        style={{
                          textAlign: c?.align || "left",
                          width: c?.width || "auto",
                          padding: "8px 16px ",
                        }}
                        className={c?.tdClassName || ""}
                        onClick={
                          width < 851 && c?.clickExpand
                            ? () =>
                              setDataToMap((prev) =>
                                prev?.map((obj) => {
                                  return {
                                    ...obj,
                                    rowExpanded:
                                      obj?.id === d?.id
                                        ? !obj?.rowExpanded
                                        : obj?.rowExpanded,
                                  };
                                })
                              )
                            : () => { }
                        }
                      >
                        {c?.renderData ? (
                          c?.renderData(d)
                        ) : (
                          <span
                            style={{
                              wordBreak: c?.width ? "break-all" : "keep-all",
                            }}
                            onClick={() =>
                              setDataToMap((prev) =>
                                prev?.map((obj) => {
                                  return {
                                    ...obj,
                                    rowExpanded:
                                      obj?.id === d?.id
                                        ? !obj?.rowExpanded
                                        : obj?.rowExpanded,
                                  };
                                })
                              )
                            }
                          // className={`${Styles.expandButton} ${
                          //   d.rowExpanded ? Styles.rotates : ""
                          // }`}
                          >
                            {d[c?.name] || "-"}
                          </span>
                        )}
                      </td>
                    ))}
                    {/* {width <= 851 && (
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              onClick={() =>
                                setDataToMap((prev) =>
                                  prev?.map((obj) => {
                                    return {
                                      ...obj,
                                      rowExpanded:
                                        obj?.id === d?.id
                                          ? !obj?.rowExpanded
                                          : obj?.rowExpanded,
                                    };
                                  })
                                )
                              }
                              className={`${Styles.expandButton} ${
                                d.rowExpanded ? Styles.rotate : ""
                              }`}
                            >
                              apa
                            </button>
                          </div>
                        </td>
                      )} */}
                  </tr>
                  {d?.rowExpanded && (
                    <tr
                      className={Styles.expandedRow}
                      style={{ background: "transparent" }}
                    >
                      {withNumbering && (
                        <td
                          className={Styles[isNumberSticky ? "sticky" : ""]}
                        />
                      )}
                      <td
                        colSpan={
                          withNumbering
                            ? columnsToMap?.length + 1
                            : columnsToMap?.length + 2
                        }
                      >
                        {expandComponent(d)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))
              : [...Array(limit ? +limit : 3)]?.map((s, sI) => (
                <tr key={sI}>
                  {withCheckbox && (
                    <td key={"skeletonCheckbox"}>{<Skeleton />}</td>
                  )}
                  {withNumbering && (
                    <td
                      key={"skeletonNumbering"}
                      className={Styles[isNumberSticky ? "sticky" : ""]}
                    >
                      {<Skeleton width="50px" />}
                    </td>
                  )}

                  {columnsToMap?.map((c, cI) => (
                    <td
                      key={cI}
                      className={c?.tdClassName || ""}
                      style={{
                        textAlign: c?.align || "left",
                        width: c?.width || "50px",
                        // zIndex: "-1",
                        // position: "relative",
                      }}
                    >
                      {c?.skeleton || <Skeleton width={c?.width} />}
                    </td>
                  ))}
                </tr>
              ))}
            {dataToMap.length > 0 &&
              dataToMap.length < limit &&
              !isLoading &&
              Array.from(
                { length: Math.max(0, limit - (dataToMap.length || 0)) },
                (_, k) => k
              ).map((_, idxLength) => (
                <tr key={idxLength}>
                  {Array.from({
                    length: columnsToMap.length + (withNumbering && 1) + (withCheckbox ? 1 : 0),
                  }).map((_, idxForEmpty) => (
                    <td
                      key={idxForEmpty}
                      style={{
                        height: heightEmpty,
                      }}
                    ></td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        {!isLoading && !data?.length && !isDetailChart && <TableEmptyHandler />}
      </div>
      {withPagination && (
        <div className={Styles.paginationWrapper}>
          <Pagination
            total={totalData}
            limit={limit}
            setLimit={setLimit}
            selectedPage={page}
            setSelectedPage={setPage}
            totalPage={totalPage}
            siblingCount={0}
            isV2
          />
        </div>
      )}
    </div>
  );
}
