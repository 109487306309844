import { useAsyncError } from "react-router-dom";
import Styles from "./style.module.scss";
import PageNotFound from "@Atom/PageNotFound";

export default function AsyncErrorPage({
  height='100%'
}) {
  const { response } = useAsyncError();
  const error = response?.data;
  const errorBackup = useAsyncError();

  if (+error?.code === 404) {
    return (
      <PageNotFound
        type="data"
        notFullWidth
        height={height}
      />
    )
  }

  return (
    <div className={Styles.container} style={{height}}>
      <div className={Styles.header}>
        <h3>Something went wrong!</h3>
      </div>
      <span className={Styles.status}>{error?.code || errorBackup?.code}</span>
      <span className={Styles.message}>
        {error?.error || errorBackup?.message}
      </span>
    </div>
  );
}
