/* eslint-disable no-unused-vars */
import Icon from '@Atom/Icon'
import Modal from '@Atom/Modal'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Styles from './style.module.scss'

export default function FileUploadModal({
  show,
  value,
  setValue = () => { },
  isMultiple = false,
  onClose = () => { },
}) {

  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = useState([])
  const [errorFileReject, setErrorFileReject] = useState({});
  const [errorFile, setErrorFile] = useState("");


  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles?.length > 0) {
        setFiles(isMultiple ? [...files, ...acceptedFiles] : [acceptedFiles[0]])
      } else {
        setErrorFile(
          "Tipe file tidak sesuai. Pastikan file memiliki format JPEG, JPG atau PNG"
        );
      }
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message:
                  "File terlalu besar. Ukuran maksimal file adalah 5MB !",
              });
            }
          });
        });
      }
    },
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    multiple: isMultiple,
    noClick: true,
    // noDrag: form?.attachment,
    maxSize: 1048576 * 5,
  });

  const fileIcon = (fileType) => {
    if (fileType.includes('pdf')) return 'pdf';
    if (fileType.includes('jpeg') || fileType.includes('jpg') || fileType.includes('png')) return 'image';
  }
  return (
    <Modal isOpen={show} onClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Upload a File</h3>
          <Icon icon={'upload'} size={24} />
        </div>
        <div className={Styles.content}>
          <span>Taruh file di kotak bawah ini</span>
          <div  {...getRootProps()} className={Styles.box}>
            <input {...getInputProps()} />
            <Icon icon={'upload'} size={40} color={'#C2C2C2'} />
            <span>Drag file ke sini untuk upload</span>
            <p>atau pilih file dengan</p>
            <button onClick={open}>Klik di sini</button>
          </div>
        </div>
        {
          !!files?.length
          &&
          <div className={Styles.files}>
            {
              files?.map((file, i) => (
                <div key={i}>
                  <Icon icon={fileIcon(file?.type)} size={24} />
                  <span>{file?.name}</span>
                  <button onClick={() => setFiles(files?.filter(obj => obj !== file))}>
                    <Icon icon={'close-circle-solid'} size={24} />
                  </button>
                </div>
              ))
            }
          </div>
        }
        <div className={Styles.actions}>
          <button>Cancel</button>
          <button onClick={() => {
            setValue(!isMultiple ? files[0] : files)
            onClose()
          }}>Upload</button>
        </div>
      </div>
    </Modal>
  )
}