import { useMemo } from 'react'
import Styles from './style.module.scss'
import Images from '@Theme/Images'
import Icon from '@Atom/Icon'

export default function AttachmentCard({
  fileName = '',
  fileType = '',
  fileUrl = ''
}) {

  const logo = useMemo(() => {
    if (fileType?.includes('image')) {
      return Images.IMAGE_LOGO
    } else if (fileType?.includes('pdf')) {
      return Images.PDF_LOGO
    } else {
      return Images.IMAGE_LOGO
    }
  }, [fileType])

  return (
    <div className={Styles.card}>
      <img src={logo} alt='' />
      <span>{fileName}</span>
      <button>
        <Icon icon={'download-square'} size={20}/>
      </button>
    </div>
  )
}