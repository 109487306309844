import PayrollSectionCard from '@Atom/PayrollSectionCard'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import { SectionTable } from '../BPJSSection'
import { useCallback, useEffect, useMemo, useState } from 'react'
import AddParameterModal from '@Molecule/_modal/AddParameterModal'
import { getComponentSalary } from '@Services/payroll/getComponentSalary'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function IncomeSection({ pksID = '', defaultIncomes = [], isLoadingScheme, setTrigger = () => { } }) {
  const [showAdd, setShowAdd] = useState(false)
  const [incomes, setIncomes] = useState(defaultIncomes || [])

  const [options, setOptions] = useState([])

  const fetchParams = useCallback(async () => {
    try {
      const { data } = await getComponentSalary(pksID)
      setOptions(data?.response)
    } catch (err) {
      console.log(err, 'error on fetching params')
    }
  }, [pksID])

  useEffect(() => {
    if (pksID) {
      fetchParams()
    }
  }, [fetchParams, pksID])


  useEffect(() => {
    setIncomes(defaultIncomes)
  }, [defaultIncomes])

  const paramsOptions = useMemo(() => {
    return options?.map(obj => {
      return {
        id: obj?.component_id,
        parameter: obj?.component_name,
        description: obj?.component_function,
        information: obj?.component_information || '',
        income: obj?.component_income || '',
        outcome: obj?.component_outcome || '',
        thp: obj?.component_thp || '',

      }
    })
    // return [
    //   {
    //     id: 11,
    //     parameter: 'Gaji Pokok 1',
    //     description: 'Gapok',
    //     information: 'Rutin',
    //     preventDelete: true,
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 12,
    //     parameter: 'Gaji Prorate 1',
    //     description: 'Penambah',
    //     information: 'Rutin',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 13,
    //     parameter: 'Gaji Prorate Rapel 1',
    //     description: 'Penambah',
    //     information: 'Rutin',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 14,
    //     parameter: 'Insentif 1',
    //     description: 'Penambah',
    //     information: 'Rutin',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 15,
    //     parameter: 'Biaya Admin Bank 1',
    //     description: 'Pengurang',
    //     information: 'THP',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 16,
    //     parameter: 'Potongan Lain-Lain (non tax) 1',
    //     description: 'Pengurang',
    //     information: 'THP',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 17,
    //     parameter: 'Potongan Pinjaman 1',
    //     description: 'Pengurang',
    //     information: 'THP',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 18,
    //     parameter: 'Asuransi 1',
    //     description: 'Tunjangan',
    //     information: 'Pengurang THP',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 19,
    //     parameter: 'Bonus 1',
    //     description: 'Tunjangan',
    //     information: 'Tidak Rutin',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    //   {
    //     id: 18,
    //     parameter: 'Insentif Non Rutin 1',
    //     description: 'Tunjangan',
    //     information: 'Tidak Rutin',
    //     income: 'Y',
    //     outcome: 'N',
    //     thp: 'N'
    //   },
    // ]
  }, [options])


  const columns = [
    {
      name: 'parameter',
      label: 'Parameter'
    },
    {
      name: 'description',
      label: 'Description'
    },

    {
      name: 'information',
      label: 'Informasi Parameter'
    },
    {
      name: 'income',
      label: '(+) Income'
    },
    {
      name: 'outcome',
      label: '(-) Outcome'
    },

    {
      name: 'thp',
      label: '(-) THP'
    }
  ]

  return (
    <PayrollSectionCard
      title='Rincian Pendapatan & Tunjangan'
      expand
      button={<button
        className={Styles.addButton}
        onClick={() => setShowAdd(true)}
      >
        <span>Tambah Parameter</span>
        <Icon icon={'add-circle'} size={16} />
      </button>}
    >
      <SectionTable
        data={incomes}
        columns={columns}
        withBorderBottom
        isLoading={isLoadingScheme}
      />
      {
        showAdd
        &&
        <AddParameterModal
          show={showAdd}
          options={paramsOptions}
          onClose={() => setShowAdd(false)}
          onSuccess={() => {
            setTrigger(makeRandomString(5))
          }}
          pksID={pksID}
        />
      }
    </PayrollSectionCard>
  )
}